<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="안전보건관리계획서 개요" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-show="editable && disabled"
                  :url="saveUrl"
                  :isSubmit="isSave2"
                  :param="data"
                  mappingType="PUT"
                  label="반려"
                  icon="delete_forever"
                  @beforeAction="savePlan2"
                  @btnCallback="saveCallback" 
                />
                <c-btn
                  v-show="editable && disabled"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  mappingType="PUT"
                  label="승인"
                  icon="save"
                  @beforeAction="savePlan"
                  @btnCallback="saveCallback" 
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant 
                  required
                  :editable="editable" 
                  :disabled="true"
                  type="edit" 
                  name="plantCd" 
                  v-model="data.plantCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-vendor
                  :editable="editable"
                  :disabled="true"
                  :selfFlag="false"
                  label="협력업체"
                  name="vendorCd"
                  v-model="data.vendorCd">
                </c-vendor>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                <c-text
                  :required="true"
                  :disabled="true"
                  :editable="editable"
                  label="공사명"
                  name="constructionName"
                  v-model="data.constructionName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-datepicker
                  :required="true"
                  :range="true"
                  :disabled="true"
                  :editable="editable"
                  label="공사기간"
                  name="period"
                  v-model="data.period"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-field
                  :required="true"
                  :editable="editable"
                  :disabled="true"
                  :data="data"
                  deptValue="manageDeptCd"
                  type="dept_user"
                  label="현업담당자/부서"
                  name="manageUserId"
                  v-model="data.manageUserId">
                </c-field>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  :disabled="!disabled"
                  :editable="editable"
                  label="반려사유"
                  name="remarks"
                  v-model="data.remarks">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="안전보건관리계획서 첨부" class="cardClassDetailForm" :collapsed="true">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-upload 
                  :attachInfo="attachInfo"
                  :editable="false">
                </c-upload>
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'safety-plan-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        vendorSafetyPlanId: '',
      }),
    },
  },
  data() {
    return {
      data: {
        vendorSafetyPlanId: '',  // 안전작업계획서 일련번호
        vendorCd: '',  // 업체코드
        plantCd: '',  // 사업장코드
        constructionName: '',  // 공사명
        startDt: '',  // 공사시작일
        endDt: '',  // 공사종료일
        manageUserId: '',  // 담당자
        manageDeptCd: '',
        vendorSafetyPlanStepCd: '',  // 승인상태
        remarks: '',  // 반려사유
        period: [],
      },
      editable: true,
      detailUrl: '',
      saveUrl: '',
      saveType: 'POST',
      isSave: false,
      isSave2: false,
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'VENDOR_SAFETY_PLAN',
        taskKey: '',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.data.vendorSafetyPlanStepCd == 'VSPSC00001';
    }
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.saveUrl = transactionConfig.sai.vendor.safety.plan.insert.url;
      this.detailUrl = selectConfig.sai.vendor.safety.plan.get.url;
      this.insertUrl = transactionConfig.sai.vendor.safety.plan.insert.url;
      this.updateUrl = transactionConfig.sai.vendor.safety.plan.update.url;
      this.deleteUrl = transactionConfig.sai.vendor.safety.plan.delete.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.vendorSafetyPlanId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.vendorSafetyPlanId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.$set(this.attachInfo, 'taskKey', this.popupParam.vendorSafetyPlanId)
        },);
      } 
    },
    savePlan() { // 승인
      this.saveUrl = this.updateUrl;
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '안전보건관리계획서를 승인하시겠습니까?', // 저장하시겠습니까?
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.data.regUserId = this.$store.getters.user.userId
          this.data.chgUserId = this.$store.getters.user.userId
          this.data.vendorSafetyPlanStepCd = 'VSPSC00002';
          this.isSave = !this.isSave
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    savePlan2() { // 반려
      this.saveUrl = this.updateUrl;
        if (this.data.remarks) { 
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: '안전보건관리계획서를 반려하시겠습니까?', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId
              this.data.chgUserId = this.$store.getters.user.userId
              this.data.vendorSafetyPlanStepCd = 'VSPSC00003';
              this.isSave2 = !this.isSave2
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: '반려사유를 입력하세요.',
            type: 'warning', // success / info / warning / error
          });
        }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
  }
};
</script>
